export const works = {
    "uz":{
        title:"Bizning xizmatlarimizdan foydalangan foydalanuvchilardan ba'zilari bilan bu yerda tanishib chiqishingiz mumkin",
        text1:"Shayxontohur tumani, Gulobod ko'chasi Milliy taomlar 20kW",
        text2:"Yunusobod tumani Bog'ishamol ko'chasi Ro'zmetov Kolbasa zavodi 100kW",
        text3:"Sergeli tumani Yo'ldosh-5 ko'chasi 20kW",
        text4:"Olmazor tumani Sogbol ko'chasi Xususiy uy 11kW",
        text5:"Sergeli tumani, Choshtepa ko'chasi Krember zavodi 10kW",
        // 
    },
    "ru":{
        title:"Здесь вы можете просмотреть некоторых пользователей, воспользовавшихся нашими услугами.",
        text1:"Шайхантахурский р-н, ул.Гулобод  Миллий таомлар 20 kW",
        text2:"Юнусабадский р-н, ул. Богишамол Предприятие по производству колбасных изделий 'Рузметов' 100kW",
        text3:"Сергелийский р-н, ул. Юлдош-5  20kW",
        text4:"Алмазарский р-н, ул. Согбол Частный дом 11kW",
        text5:"Сергелийский р-н, ул.Чоштепа Предприятие по производству сладостей 'Krember' 10kW",
    }
}


// Юнусобод тумани Богишамол кучаси Рузметов Колбаса завод 100kW

// Сергели тумани Юлдош-5 кучаси. 20kW 

// Олмазор тумани Согбол кучаси Частный дом 11kW

// Сергели тумани, Чоштепа кучаси Krember заводи. 10kW