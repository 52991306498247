export const navbar = {
    "uz":{
        link_1:"Asosiy",
        link_2:"kompaniyalar guruxi",
        link_3:"Biz haqimizda",
        link_4:"Afzalliklari",
        link_9:"bizning ishlarimiz",
        link_5:"mahsulotlar",
        link_6:"hamkorlar",
        link_7:"aloqa",
        link_8:"menu"
    },
    "ru":{
        link_1:"Главная",
        link_2:"группа компаний",
        link_3:"о компании",
        link_4:"преимущества",
        link_9:"наши работы",
        link_5:"продукция",
        link_6:"партнёры",
        link_7:"контакты",
        link_8:"меню",
    }
}
